/*Functions*/
    $('#cabecalho > div.conteiner > div.row-fluid > div:nth-child(1) > div.superior.row-fluid > div > a').html('Entrar | Cadastrar');
        $('.carrinho>a span').html('Itens na Cesta');
        $('.carrinho.vazio>a span').html('0 Itens na Cesta');
        
        $("#corpo .listagem .titulo-categoria strong").after($('<span class="vertodos">+ Ver Todos</span>'));

         //Copyright
    if(LOJA_ID == 439168) {
    true;}
    else {
    $(  '<div style="top: 80px!important;z-index:999999999!important;position:relative!important;background:#fff!important;display:block!important;margin:0 auto!important;padding:25px!important;color:red!important;font-size:15px;text-align:center!important;box-shadow: 0px 0px 40px -12px #000!important;">'+
        '<h2>Material rastreado por possuir direitos autorais de GestOn AgÃªncia Digital.</h2><p>Para adquirir este ou outros trabalhos, acesse www.geston.com.br.</p><p>A ocultaÃ§Ã£o desta mensagem nÃ£o fornece o direito de uso, sob pena de sanÃ§Ãµes legais.</p></div>').insertBefore('#cabecalho');
    }
        
        /*Página Produto*/
        $('<div class="banner-prod span12">'+
                        '<img src="//cdn.awsli.com.br/439/439168/arquivos/banner-desc1.jpg" alt="Reborn Bebe" title="Reborn Bebe">'+
                        '<img src="//cdn.awsli.com.br/439/439168/arquivos/tarja-desc1.png" alt="Reborn Bebe" title="Reborn Bebe">'+
                    '</div>'
                    ).insertAfter('.pagina-produto #corpo > div > div.secao-principal.row-fluid.sem-coluna > div > div:nth-child(1)');

        /*Rodape*/

        $('<div class="span4 envio">'+
            '<span class="titulo cor-secundaria">Formas de Envio</span>'+
            '<ul class="formas-envio">'+
            '<li><a href="/pagina/prazo-de-entrega.html"><img src="https://cdn.awsli.com.br/439/439168/arquivos/frete.png"></a></li>'+
        '</div>'
        ).insertAfter('#rodape > div.pagamento-selos > div > div > div.span4.selos');
        
        $('#rodape > div:nth-child(3)').addClass('footer-logo');
        $('#rodape > div:last-child .row-fluid > div:last-child a'
            ).after('<span class="geston">Design:</span><a href="http://www.geston.com.br/" '+
            'target="_blank" style="width:100px;opacity:1 !important;display:inline-block !important;visibility: visible !important;'+
            'margin: 0 !important;position: static !important;overflow: visible !important;padding: 0 5px !important;">'+
            '<img src="https://cdn.awsli.com.br/439/439168/arquivos/logo-sem-ag.png" alt="GestOn Agência Digital" /></a></a>');

